























import {Component, Prop, Vue} from "vue-property-decorator";
import {UserRoleEnum} from "@/utils/Enums";
import Constants from "@/utils/Constants";
import ComplementsCoutsSuppAdministrationService from "@/services/ComplementsCoutsSuppAdministrationService";
import ComplementsCoutsSupp from "@/models/ComplementsCoutsSuppAdministration";

type Data = {
  columns: Array<Object>;
  rows: Array<Object>;
};

@Component({
  components: {
    Table: () => import("@/components/Shared/Table/Table.vue"),
    ComplementsCoutsSuppModal: () =>
        import("@/components/Shared/Table/ComplementsCoutsSuppModal.vue"),
  },
})

export default class ComplementsCoutsSuppAdministration extends Vue {
  public data: Data = {
    columns: [],
    rows: [],
  };
  public isModalVisible: boolean = false;
  private modalProps: any = null;
  private url: string = "";
  private modifiedComplementsCoutsSupps: Array<ComplementsCoutsSupp> = [];

  private get language() {
    return this.$i18n.locale;
  }

  private get isNotAllowedToEdit(): Boolean {

    return !this.$store.getters.isAllowed(UserRoleEnum.centralAdministrator);
  }

  /**
   * ##########
   *  Methods
   * ##########
   */
  public validate(obj: any) {
    //
    (<any>this.$refs.table).modify(obj);
    this.isModalVisible = false;
  }

  public openModal(props: any) {
    this.isModalVisible = true;
    this.modalProps = props;
  }

  public async save() {
    try {
      const response = await ComplementsCoutsSuppAdministrationService.save(
          this.modifiedComplementsCoutsSupps
      );
      if (response.status === 200) {
        this.$notify({
          group: "global",
          type: "success",
          title: this.$t(
              "bopModelSetup.complementsCoutsSuppAdministration.saveSuccessTitle"
          ).toString(),
          text: this.$t(
              "bopModelSetup.complementsCoutsSuppAdministration.saveSuccess"
          ).toString(),
        });
        this.refresh();
      }
    } catch {
      this.$notify({
        group: "globalError",
        type: "error",
        title: this.$t(
            "bopModelSetup.complementsCoutsSuppAdministration.errorsTitle"
        ).toString(),
        text: this.$t(
            "bopModelSetup.complementsCoutsSuppAdministration.incorrectValue"
        ).toString(),
        duration: Constants.DEFAULT_WAIT_ERROR_NOTIFICATIONS,
      });
    }
  }

  public refresh(): void {
    this.modifiedComplementsCoutsSupps = [];
    (<any>this.$refs.table).refreshAPI();
    this.$store.commit("changeHasBeenModified", false);
  }

  /**
   * Hooks
   */
  public  created() {

    this.url = ComplementsCoutsSuppAdministrationService.getComplementsCoutsSuppAdministrationTableUrl(this.language);
    this.data.columns = [
      {
        name: "editable",
        title: this.$t("bopModelSetup.TypeTravaux").toString(),
        sortField: `typeTravauxName`,
        property: `typeTravauxName`,
        isNotEditable: true,
      },
      {
        name: "complementsCoutsSupp",
        sortField: "complementsCoutsSuppKeys",
        title: this.$t("bopModelSetup.complementsCoutsSuppAdministrationValues"),
        property: "complementsCoutsSuppKeys",
      },
    ];
  }
}
